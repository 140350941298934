//import React from 'react';

import {
  Section,
  SectionText,
  SectionTitle,
  SectionAccent,
} from '../Section/Section.styles';
import Button from '../Button/Button';
import { HeroContainer, LeftSection } from '../Hero/HeroStyles';

import BgAnimation from '../BackgroundAnimation/BackgroundAnimation';

const Hero = () => (
  <HeroContainer>
    <Section grid hero>
      {/* <HeroContent> */}
      {/* <Section> */}
      <LeftSection>
        <SectionTitle>
          Sam Miller, <SectionAccent>Software Engineer.</SectionAccent>
        </SectionTitle>
        <br />
        <SectionText>
        Highly motivated full stack software engineer with 5+ years of professional development experience with the full SDLC, CI/CD pipelines, and cross-team coordination.  High and low level language experience.  Currently expanding skills in Rust and Solana blockchain development, and actively seeking opportunities in this emerging technology.
        </SectionText>
        {/* <Button onClick={() => (window.location = 'https://google.com')}>
          Contact
        </Button> */}
      </LeftSection>
      {/* </Section> */}
      {/* </HeroContent> */}
      <BgAnimation />
    </Section>
  </HeroContainer>
);

export default Hero;
